import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/views/Login";
import Register from "./components/views/Register";
import RegisterEmployee from "./components/views/RegisterEmployee";
import Forgot from "./components/views/Forgot";
import ResetPassword from "./components/views/ResetPassword";
import RegisterProvider from "./components/views/RegisterProvider";

const Auth = () => {
  return (
    <Router>
      <Switch>
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/register" component={Register} />
        <Route path="/organisation/employee/:id" component={RegisterEmployee} />
        <Route path="/organisation/provider/:id" component={RegisterProvider} />

        <Route path="/" component={ResetPassword} />
      </Switch>
    </Router>
  );
};

export default Auth;
