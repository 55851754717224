import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Form from "../../utilities/Forms";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const RegisterProvider = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState(null);

  const baseUrl = "https://api.gaphealth.ai";
  const { id } = useParams();

  const validateRegister = () => {
    let isValid = true;

    let validator = Form.validator({
      firstname: {
        value: firstname,
        isRequired: true,
      },
      lastname: {
        value: lastname,
        isRequired: true,
      },
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const register = (e) => {
    e.preventDefault();

    const validate = validateRegister();

    if (validate) {
      Swal.showLoading();
      setValidate({});
      axios
        .post(`${baseUrl}/org/provider/${id}/connect`, {
          username: `${firstname} ${lastname}`,
          first_name: firstname,
          last_name: lastname,
          password: password,
        })
        .then((response) => {
          Swal.close();
          setFirstname("");
          setLastname("");
          setEmail("");
          setPassword("");

          setValidate({});

          Swal.fire({
            title: "Success!",
            text: "Your employee account has been created and linked successfully! Please check your email to activate your new account. Click 'Okay' to be redirected to the Sign in page.",
            icon: "success",
            confirmButtonText: "Okay",
            confirmButtonColor: "#00bfa6",
          }).then((res) => {
            window.location = "https://org.gaphealth.ai";
          });
        })
        .catch((error) => {
          Swal.hideLoading();
          console.log("An error occurred:", error?.response);
          let errorMessage = error?.response?.data?.message
            ? error?.response?.data?.message
            : "There was an error submitting your request. Please check your network connection and try again. If this error persists, kindly contact support.";
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Okay",
            confirmButtonColor: "#00bfa6",
          }).then((res) => {});
        });
    }
  };

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  useEffect(() => {
    getEmpData(id);
  }, []);

  const getEmpData = (empId) => {
    Swal.showLoading();
    console.log(empId);
    axios
      .get(`${baseUrl}/org/provider-data/${empId}`)
      .then((response) => {
        Swal.close();
        console.log(response.data);
        setData(response.data);
        setEmail(response.data.email);
        setValidate({});
        setPassword("");
        //   setConfirmPassword("");
      })
      .catch((error) => {
        Swal.hideLoading();
        console.log("An error occurred:", error?.response);
        let errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : "There was an error submitting your request. Please check your network connection and try again. If this error persists, kindly contact support.";
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "Okay",
          confirmButtonColor: "#00bfa6",
        }).then((res) => {});
      });
  };

  const connectAccount = () => {
    Swal.showLoading();
    axios
      .get(`${baseUrl}/org/provider/${id}/connect`)
      .then((response) => {
        Swal.close();
        console.log(response);

        Swal.fire({
          title: "Success!",
          text: "Your healthcare provider account has been linked successfully! Click 'Okay' to be redirected to the Sign in page.",
          icon: "success",
          confirmButtonText: "Okay",
          confirmButtonColor: "#00bfa6",
        }).then((res) => {
          window.location = "https://org.gaphealth.ai";
        });
      })
      .catch((error) => {
        Swal.hideLoading();
        console.log("An error occurred:", error?.response);
        let errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : "There was an error submitting your request. Please check your network connection and try again. If this error persists, kindly contact support.";
        Swal.fire({
          title: "Error!",
          text: errorMessage,
          icon: "error",
          confirmButtonText: "Okay",
          confirmButtonColor: "#00bfa6",
        }).then((res) => {});
      });
  };

  return (
    <div className="row g-0 auth-wrapper">
      <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
        <div className="auth-background-holder-register-provider "></div>
        <div className="auth-background-mask"></div>
      </div>

      <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
        <div className="d-flex flex-column align-content-end">
          <div className="auth-body mx-auto">
            <p style={{ fontSize: "2rem", fontWeight: "600" }}>
              {data?.organisation?.name}
            </p>
            <strong>
              <p>Health Provider Registration</p>
            </strong>
            {data?.user?.email ? (
              <div className="auth-form-container text-start">
                <form
                  className="auth-form"
                  method="POST"
                  onSubmit={register}
                  autoComplete={"off"}
                >
                  <div className="name mb-3">
                    <p>
                      Welcome back, <strong>{data?.user?.username}</strong>
                    </p>
                    <p>
                      Click on the button below to connect to{" "}
                      {data?.organisation?.name} as an health provider.
                    </p>
                  </div>

                  <div className="text-center">
                    <button
                      onClick={() => {
                        connectAccount();
                      }}
                      type="button"
                      className="btn btn-primary w-100 theme-btn mx-auto"
                    >
                      Connect
                    </button>
                  </div>
                </form>

                <hr />
                <div className=" text-center ">
                  <strong>
                    <p className="pb-0 mb-0">Get Our Provider App</p>
                  </strong>
                </div>
                <div className="auth-option text-center ">
                  <a
                    href="https://apps.apple.com/gh/app/gaphealth-patient/id1606694635"
                    target={"_blank"}
                  >
                    <img
                      src="/appstore-badge.png"
                      style={{ width: "8rem" }}
                    ></img>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gaphealthtech.gaphealthpatient"
                    target={"_blank"}
                  >
                    <img
                      src="/googleplay-badge.png"
                      style={{ width: "10rem" }}
                    ></img>
                  </a>
                </div>
                <div className="auth-option text-center pt-2">
                  2022 &copy;{" "}
                  <a
                    className="link-nostyle"
                    href="https://gaphealth.ai"
                    target={"_blank"}
                  >
                    GAPhealth Technologies Inc.{" "}
                  </a>
                </div>
              </div>
            ) : (
              <div className="auth-form-container text-start">
                <form
                  className="auth-form"
                  method="POST"
                  onSubmit={register}
                  autoComplete={"off"}
                >
                  <div className="name mb-3">
                    <input
                      type="text"
                      className={`form-control ${
                        validate.validate && validate.validate.firstname
                          ? "is-invalid "
                          : ""
                      }`}
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      placeholder="First name"
                      onChange={(e) => setFirstname(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.firstname
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.firstname
                        ? validate.validate.firstname[0]
                        : ""}
                    </div>
                  </div>

                  <div className="name mb-3">
                    <input
                      type="text"
                      className={`form-control ${
                        validate.validate && validate.validate.lastname
                          ? "is-invalid "
                          : ""
                      }`}
                      id="lastname"
                      name="lastname"
                      value={lastname}
                      placeholder="Last name"
                      onChange={(e) => setLastname(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.lastname
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.lastname
                        ? validate.validate.lastname[0]
                        : ""}
                    </div>
                  </div>

                  <div className="email mb-3">
                    <input
                      type="email"
                      className={`form-control ${
                        validate.validate && validate.validate.email
                          ? "is-invalid "
                          : ""
                      }`}
                      id="email"
                      name="email"
                      value={email}
                      disabled={true}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.email
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.email
                        ? validate.validate.email[0]
                        : ""}
                    </div>
                  </div>

                  <div className="password mb-3">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          validate.validate && validate.validate.password
                            ? "is-invalid "
                            : ""
                        }`}
                        name="password"
                        id="password"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) => togglePassword(e)}
                      >
                        <i
                          className={
                            showPassword ? "far fa-eye" : "far fa-eye-slash"
                          }
                        ></i>{" "}
                      </button>

                      <div
                        className={`invalid-feedback text-start ${
                          validate.validate && validate.validate.password
                            ? "d-block"
                            : "d-none"
                        }`}
                      >
                        {validate.validate && validate.validate.password
                          ? validate.validate.password[0]
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 theme-btn mx-auto"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>

                <hr />
                <div className=" text-center ">
                  <strong>
                    <p className="pb-0 mb-0">Get Our Provider App</p>
                  </strong>
                </div>
                <div className="auth-option text-center ">
                  <a
                    href="https://apps.apple.com/gh/app/gaphealth-patient/id1606694635"
                    target={"_blank"}
                  >
                    <img
                      src="/appstore-badge.png"
                      style={{ width: "8rem" }}
                    ></img>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gaphealthtech.gaphealthpatient"
                    target={"_blank"}
                  >
                    <img
                      src="/googleplay-badge.png"
                      style={{ width: "10rem" }}
                    ></img>
                  </a>
                </div>

                <div className="auth-option text-center pt-2">
                  2022 &copy;{" "}
                  <a
                    className="link-nostyle"
                    href="https://gaphealth.ai"
                    target={"_blank"}
                  >
                    GAPhealth Technologies Inc.{" "}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterProvider;
